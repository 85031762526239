import React from "react"
import { graphql } from "gatsby"

import { rhythm } from '../utils/typography'
import { themeStyles } from '../utils/theme'

import Layout from '../components/Layout'
import Seo from "../components/Seo"

import Img from 'gatsby-image'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const imgFluid = data.lost.childImageSharp.fluid

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo title="404: LOST" />
        <div css={themeStyles.textPadding}>
          {imgFluid &&<Img fluid={imgFluid} css={{marginBottom: rhythm(1)}}/> }
          <h1>Are you LOST?</h1>
          <p>Looks like you can&#39;t get there from here... Perhaps you need a <a href="/map/">map</a>?</p>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    lost: file(relativePath: { eq: "lost.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`